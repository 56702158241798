<template>
    <v-list light align="left" width="100%"  color="#f9ffb9">
         <input type="file" style="padding-top:120px" webkitdirectory multiple @change="UploadFilesTest($event)" id="folderuploadinput" accept=".pdf">
                    <v-subheader inset>{{SelectedFolder.path}}<v-layout class="justify-end"><v-icon @click="AddFolder()">mdi-folder-plus</v-icon></v-layout></v-subheader>

                      <v-btn @click="ReassignSelectedFolder()" v-if="SelectedFolder && SelectedFolder.path !== DefaultFolder.path"><v-icon>mdi-arrow-up-thick</v-icon>Back</v-btn>
                      <!-- <v-btn @click="SaveUploadFolders()">Save Folders</v-btn> -->

                      <v-subheader inset>Folders {{RelatedFolders.length}}</v-subheader>
                      <v-list-item v-for="(folder,i) in RelatedFolders" :key="folder.itemObjKey">
                        <v-list-item-avatar>
                          <v-icon  @click="ActivateSelectedFolder(folder,i)"
                            :class="[folder.iconClass]"
                             v-text="folder.icon"
                          ></v-icon>
                        </v-list-item-avatar>
                        <v-layout row class="justify-start">

                          <v-list-item-content>
                              <v-list-item-title><v-text-field v-model="folder.title"></v-text-field></v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-content>
                              <v-list-item-title>{{folder.subtitle}}</v-list-item-title>
                            </v-list-item-content>

                          </v-layout>

                        </v-list-item>
                        <v-divider inset></v-divider>
                        <v-layout class="justify-end" v-if="SelectedFolder.path !== DefaultFolder.path"><input
                          style="display: none"
                          type="file"
                           @change="AddFiles($event)"
                          ref="MultipleFileUpload3FolderfileInputer"
                           :multiple="true"
                          >
                          <v-btn @click="$refs.MultipleFileUpload3FolderfileInputer.click()"
                          fab
                          small raised color="pop"
                          top
                          right
                          absolute
                          
                        ><v-icon color="white">mdi-plus-thick</v-icon></v-btn></v-layout>

                        <v-card flat min-height="200px">

                          <v-list-item
                            v-for="item in SelectedFolder.Files"
                             :key="item.itemObjKey"

                          >

                            <v-list-item-content>
                              <v-list-item-title>{{item.title}}</v-list-item-title>
                              <v-list-item-subtitle>{{item.subtitle}}</v-list-item-subtitle>
                            </v-list-item-content>

                            <v-list-item-content>
                              <v-list-item-title>{{item.type}}</v-list-item-title>
                              <v-list-item-subtitle><v-icon
                                 :class="[item.iconClass]"
                                 v-text="item.icon"
                              ></v-icon></v-list-item-subtitle>
                            </v-list-item-content>

                            <v-list-item-action>
                              <v-btn icon @click="GetShortLivedURL(item)" >
                                <v-icon color="grey lighten-1">mdi-eye</v-icon>
                              </v-btn>
                            </v-list-item-action>
                          </v-list-item>
                        </v-card>
                    </v-list>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import format from 'date-fns/format'

export default {
    props: ['tab','section','sectionindex','Fields','AppisDarkMode',
    'CanEdit','Record','Cols','Business_Unit','PrimaryFieldName','RelatedObj',
    'AppointmentsQuery','ActivitiesArray','SingleFormEditing','EntityName',
    'EntityCollectionRef','BaseStorageRef','EntityDataRef','CanCreate','CanDelete'
    ,'CanGet','SampleOnly'],
    components: {

    },
    data(){
        return{
            selectedDocumentList: {},																								
            selectedDocumentsArray: [],
            SelectedFolder: '',
            FolderFiles:[],
            rules: {
            telnr: value => {
              const pattern = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
              return pattern.test(value) || "Not a telephone number.";
            },
            min8Chars: value => value.length >= 8 || "Min. 8 characters",
            required: value => !!value || "Required.",
            email: value => {
              const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
              return pattern.test(value) || "Invalid e-mail.";
            },
            url: value => {
              const urlpattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|'+ // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
            '(\\#[-a-z\\d_]*)?$','i'); 
              return urlpattern.test(value) || "Invalid Link.";
            }
          },
            ViewFileDialog: false,
            ViewFileURL: '',
            ViewIMGURL: '',
            
        }
    },
    computed:{
        RelatedFolders() {
    return this.UploadFolders.filter(folder => {
      return folder.rootpath === this.SelectedFolder.path
    })
  }, 
        UploadFolders(){
            return this.section.DataArray
        },
        DefaultFolder(){
            return {title: '',path: this.EntityName.split(' ').join('')+'/'+this.$route.params.id+'/'}
        },
        
        SocialItemInteractMethod(){
        return this.$store.state.SocialItemInteractMethod
        },
        SocialItemInteractMethodProp(){
          return this.$store.state.SocialItemInteractMethodProp
        },
        ImageHeight(){
        return window.innerHeight
        },
        ImageWidth(){
        return window.innerWidth
        },
        FinalStorageRef(){
            return this.BaseStorageRef+this.SectionStorageRef
        },
        SectionStorageRef(){
            return this.section.Name.split(' ').join('')+'/'+this.Record.id+'/'
        },
        SectionDBRef(){
            return this.EntityDataRef.collection(this.section.Name.split(' ').join('').split('_').join('').toLowerCase())
        },
        withIcon(){
      return this.section.DataArray.map(doc => {
        console.log(doc)
        if(doc.FileType.includes('pdf')){
          doc.FileTypeIcon = 'mdi-file-pdf'
          doc.FileTypeIconColor = 'red'
          //doc.FileType = doc.FileType.split('/')[1]
        }
        else{
          doc.FileTypeIcon = 'mdi-file-question-outline'
          doc.FileTypeIconColor = 'blue'
          //doc.FileType = doc.FileType.split('/')[1]
        }
        return doc
      })
    },
        UsersArray(){
      return this.$store.state.UsersArray
    },
        userLoggedIn () {
      return this.$store.getters.user
    },
    userBU () {
      return this.$store.state.buobj
    },
    userIsAdmin () {
    return this.$store.state.IsAdmin
  },
        HeaderDarkLightStyle(){
            if(this.AppisDarkMode){
                return 'white--text font-weight-light'
            }
            else{
                return 'primary--text font-weight-light'
            }
            },
    },
    watch: {
      SocialItemInteractMethod(v){   
        let acceptedmethods = []   
        if(v && acceptedmethods.includes(v) && this.section.DisplayName === this.SocialItemInteractMethodProp){
          if(this.SocialItemInteractMethodProp){
            //console.log('yes with prop',this.SocialItemInteractMethod,this.SocialItemInteractMethodProp)
            this[this.SocialItemInteractMethod](this.SocialItemInteractMethodProp)
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }
          else{ 
            this[this.SocialItemInteractMethod]()
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }          
        }
      },
    },
    created(){
       console.log('this.EntityName',this.EntityName)
       this.SelectedFolder = this.DefaultFolder
    },
    methods:{
        UploadFilesTest(event,entity){																								
        this.selectedDocumentList = event.target.files || event.dataTransfer.files																								
 			//console.log(this.selectedDocumentList)																					
        let FileListArray = Array.from(this.selectedDocumentList)																								
        this.selectedDocumentsArray = []																								
        FileListArray.map((file,index) => {		
            //console.log('file',file)																						
					let ref = this.SectionDBRef.doc();																			
          let id = ref.id;																								
          let fileobj = {																								
            name: file.name,
            title: file.name,																								
            type: file.type,																								
            lastModifiedDate: file.lastModifiedDate,																								
            webkitRelativePath: file.webkitRelativePath,																							
            localpath: URL.createObjectURL(file),
            path: this.DefaultFolder.path+file.webkitRelativePath,	
            rootpath: this.DefaultFolder.path+file.webkitRelativePath.split('/'+file.name)[0],																		
            id: id																								
          }																								
																								
            this.selectedDocumentList[index].id = id																		
          this.selectedDocumentsArray.push(fileobj)	
          if(index-1+2 === this.selectedDocumentsArray.length){
              this.PrepareFolders(this.selectedDocumentsArray)
          }          																							
        })																								
    	//console.log(this.selectedDocumentsArray)																						
      },
      PrepareFolders(selectedDocumentsArray){
          let array = selectedDocumentsArray.map(item => {
              return item.rootpath
          })
          let uniq = [...new Set(array)];
          console.log(uniq)
          let folderandfiles = uniq.map(item => {
              let titlesplit = item.split('/')
              //console.log(length,item)
              let folderobj = { icon: 'mdi-folder-outline', iconClass: 'grey lighten-1 white--text', title: item.split('/')[titlesplit.length-1], subtitle: 'Jan 17, 2019', relatedrecordid: this.$route.params.id, path: item, Files: [] }
                folderobj.rootpath = folderobj.path.split(folderobj.title)[0]
                if(folderobj.rootpath !== this.DefaultFolder.path){
                    folderobj.rootpath = folderobj.rootpath.slice(0, -1)
                }
                folderobj.Files = selectedDocumentsArray.filter(child => { return child.rootpath === folderobj.path})
                return folderobj
          })
          console.log(folderandfiles,this.SelectedFolder)
          this.section.DataArray = folderandfiles
          //RelatedFolders
      },
      AddFolder(){
  let NewFolder =
    { icon: 'mdi-folder-outline', iconClass: 'grey lighten-1 white--text', title: 'New Folder', subtitle: 'Jan 17, 2019', relatedrecordid: this.$route.params.id, rootpath: this.SelectedFolder.path, path: this.SelectedFolder.path+'New Folder', Files: [] }
  this.UploadFolders.push(NewFolder)

  this.SaveUploadFolders()
  },

        AddFiles(event){
            let fileList = event.target.files || e.dataTransfer.files
        Array.from(Array(fileList.length).keys()).map(x => {
            this.AddSingleFile(fileList[x])
        })
        },

        OpenFile(item){
        let routeid = item.path
        let routeData = routeid;
        window.open(routeData.href, '_blank');
        },
        AddSingleFile(file) {
    var storageRef = firebase.storage().ref(this.SelectedFolder.path + file.name);
    var uploadTask = storageRef.put(file);
            uploadTask
            .then(snapshot => snapshot.ref.getDownloadURL())
              .then((url) => {
                  const newfile = {
                          title: file.name,
                          type: file.type,
                          rootpath: this.SelectedFolder.path,

                          path: url
                          }

              this.UploadFolders[this.SelectedFolder.index].Files.push(newfile)
              this.SaveUploadFolders()
              })
  },
        GetShortLivedURL(item){
      console.log(item)
       const functions = firebase.functions();
        const GetSignedInURL = functions.httpsCallable('GetSignedInURL');
        GetSignedInURL(item).then(result => {
          console.log(result)
          if(item.FileType.includes('image')){
            this.ViewIMGURL = result.data[0]
            console.log(window.innerHeight)
            this.ViewFileDialog = true
          }
          else{
            this.ViewFileURL = result.data[0]
            var ua = navigator.userAgent.toLowerCase();
            console.log(ua)
            var isAndroid = ua.indexOf("android") > -1; //&& ua.indexOf("mobile");
            if(isAndroid) {
              window.open(this.ViewFileURL)
            }
            else{
              console.log(window.innerHeight)
              this.ViewFileDialog = true
            }
          }
        })
    },
    ActivateSelectedFolder(folder){
  this.SelectedFolder = folder
  console.log(this.SelectedFolder)
  },
    
  ReassignSelectedFolder(){
      console.log(this.UploadFolders,this.SelectedFolder)
  let tempfolder = this.SelectedFolder
  let tempname = this.SelectedFolder.title
  let namelength = tempname.length+1
  let oldpath = this.SelectedFolder.path
  let newpath = oldpath.slice(0,-namelength)+'/'
    console.log(tempname,namelength,oldpath,newpath)
  if(newpath === this.DefaultFolder.path){
    this.SelectedFolder = this.DefaultFolder
    this.FolderFiles = []
  }

  else{
  let NewSelectedFolder = this.UploadFolders.find(folder => folder.path == newpath.slice(0, -1))
  let NewFolderIndex = this.UploadFolders.indexOf(NewSelectedFolder)

    if(this.UploadFolders[NewFolderIndex].Files){
          this.FolderFiles = this.UploadFolders[NewFolderIndex].Files
          this.SelectedFolder = this.UploadFolders[NewFolderIndex]
        }

    else{
        this.FolderFiles= []
        this.SelectedFolder = this.UploadFolders[NewFolderIndex]
        }
  }
},
SaveUploadFolders(){
    let UploadFolderswithPath = this.UploadFolders.map(folder => {
    let newnamedpath = folder.rootpath+folder.title+'/'
    folder.path = newnamedpath
    return folder
  })
    console.log(this.SectionDBRef,this.UploadFolders)
    // db.collection(this.Op).doc(this.$route.params.id).update({
    //   uploadfolders: this.UploadFolders,
    //   Modified_By: {Full_Name: this.userLoggedIn.Full_Name,id: this.userLoggedIn.id},
    //   Modified_Byid: this.userLoggedIn.id,
    //   Modified_On: new Date()
    // })
  },
    }
}
</script>

<style>

</style>